import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

//     <App />,
    

// );

ReactDOM.render(//aqui se ponen los componentes que se quieren renderizar
  
    // <App saludo="buenass" >
    <App class="app"/>,
  document.getElementById('root')
);

